import React, { useState } from "react"
import * as styles from './styles/therapies.module.css'
import classnames from 'classnames/bind'
import Hero from "../components/HeroSection/Hero"
import Intro from "../components/IntroSection/Intro"
import Card from "../components/Card/Card"
import Container from "../components/Container/Container"
import Footer from "../components/Footer/Footer"
import Newstart from "../components/NewstartSection/Newstart"
import hydroThumbnail from '../images/hydro-video-thumbnail.png'
import bath1 from '../images/bath-with-towel.png'
import bath2 from '../images/bath-with-flowers.png'
import herbs from '../images/kruidentherapie.png'
import counselling from '../images/lifestyle-counseling.png'
import detox from '../images/detox.png'
import hydrotherapy from '../images/hydrotherapy.png'
import ContactForm from "../components/ContactForm/ContactForm"

const cx = classnames.bind(styles);

const Therapies = () => {
  const [active, setActive] = useState(1);

  return (
    <main className={cx("base")}>
      <title>Yirpa Ministries | Home</title>
      <Hero mini />
      <Intro title={"Therapieën"}>
        Yirpa is een Christelijke praktijk die natuurlijke behandelingen gebruikt om herstel in het lichaam te bevorderen en tot stand te brengen.
      </Intro>

      <Newstart />

      <div className={cx("introCards")}>
        <Card active={active === 1} icon={<span>shower</span>} onClick={() => setActive(1)}>
          Hydrotherapie
        </Card>
        <Card active={active === 2} icon={<span>grass</span>} onClick={() => setActive(2)}>
          Kruidentherapie
        </Card>
        <Card active={active === 3} icon={<span>question_answer</span>} onClick={() => setActive(3)}>
          Lifestyle counselling
        </Card>
        <Card active={active === 4} icon={<span>emoji_nature</span>} onClick={() => setActive(4)}>
          Detox programma
        </Card>
      </div>


      {active === 1 &&
        <>
          <div className={cx("mainContent")}>
            <Container style={{ maxWidth: "700px" }}>
              <h1>Hydrotherapie</h1>
              <p>Hydrotherapie, of watertherapie, is een behandelingsvorm die uitwendig wordt toegepast met behulp van water.  In Nederland is dit nog niet zo bekend, maar in de geschiedenis en op veel plekken in de wereld wordt het al langer toegepast.</p>
              <p>Fysiotherapeuten maken soms gebruik van watertherapie en ook bij honden wordt het regelmatig toegepast. Ook zijn er over de gehele wereld leefstijlcentra die watertherapie toepassen. Een aantal voorbeelden zijn: Uchee Pines, Die Arche, Wildwood Lifestyle Center, Fredheim Lifestyle Centre, Manna House.</p>
              <p>De meest bekende vormen van watertherapie zijn de russische stoombaden en
              warmwaterbronnen in wellnessoorden.
              </p>

              <figure>
                <img src={hydroThumbnail} alt='Filmpje over hydrotherapie' />
                <figcaption>Wisseldouche: een voorbeeld van effectieve immuunversterkende hydrotherapie</figcaption>
              </figure>
            </Container>

            <div className={cx("howItWorks")}>
              <Container flex>
                <div>
                  <h2>De werking</h2>
                  <p>Watertherapie werkt door met het lichaam samen te werken om de zelfherstellende mechanismen te bevorderen. Het heeft effect op de volgende systemen van het lichaam: de bloed- en lymfatische circulatie, het zenuwstelsel en de huid.
                </p>
                <p>Zowel warm als koud water wordt gebruikt tijdens de therapie. Door warmte en of kou toe te passen op de huid vinden in het lichaam drie reacties plaats.</p>
                </div>

                <img src={bath1} />
              </Container>
            </div>

            <div className={cx("benefitsList")}>
              <Container flex>
                <img src={bath2} />
                <div>
                  <h3>1. Verhoogde bloedcirculatie</h3>
                  <p>Bij de toepassing van warmte en/of kou ontstaat er een verhoging van de doorbloeding, voornamelijk naar de oppervlakte (huid). Dit is bevorderlijk omdat er bij ziekte vaak een opeenhoping van bloed is in een bepaald deel van het lichaam. Verder zorgt een verhoogde doorbloeding voor de toevoer van voeding en zuurstof naar de cellen en de afvoer van afvalstoffen uit de cellen.</p>

                  <h3>2. Stimulering van het lichaam om ziekte tegen te gaan</h3>
                  <p>Door warmte wordt de aanmaak van witte bloedlichamen gestimuleerd en wordt de activiteit van deze bloedcellen verhoogd. Kou stimuleert afgifte van CO2, dit zorgt ervoor dat het bloed meer alkalisch wordt. Witte bloedlichamen functioneren beter in een alkalische omgeving.</p>

                  <h3>3. Bevordering van de uitscheiding van afvalstoffen</h3>
                  <p>De uitscheiding van afvalstoffen wordt bevorderd door zweten, wat wordt veroorzaakt doordat het lichaam warmte probeert kwijt te raken. Uitscheiding van afvalstoffen vindt ook plaats door toename van de uitscheiding van urine, wat voornamelijk door warme baden wordt veroorzaakt. Ook de versnelde ademhaling waardoor het lichaam CO2 kwijtraakt, is een manier van afvaluitscheiding.</p>
                </div>

              </Container>

            </div>

          </div>

          <img src={hydrotherapy} style={{ marginBottom: "-5px" }} />
        </>}

      {active === 2 &&
        <>
          <div className={cx("mainContent")}>
            <Container style={{ maxWidth: "700px" }}>
              <h1>Kruidentherapie</h1>

              <p>Kruiden worden ook ingezet bij de bevordering van genezing.</p>

              <p>In de geschiedenis is er al sinds mensenheugenis gebruik gemaakt van kruiden om het herstel bij ziekten te bevorderen. Een voorbeeld in het verre verleden was de Griekse arts Hippocrates. Hij wordt de vader van onze geneeskunde genoemd en leefde 400 jaar voor Christus. Hippocrates had een wetenschappelijke benadering ten aanzien van geneeskunst gebaseerd op de werking van de natuur. Hij besteedde aandacht aan de patiënt zelf en aan zijn levensomstandigheden.</p>

              <p>In de achttiende eeuw slaagde men erin om stoffen uit kruiden te isoleren. Het werkzame bestanddeel salicine dat in de witte wilgenbast zit, is als basis gebruikt voor de ontdekking van acetylsalicylzuur, dat door Bayer als eerste onder de merknaam Aspirine op  de markt werd gebracht. Toen kwam de  kruidengeneeskunde op de achtergrond. In deze tijd was de opmars van de wetenschap en technologie. De chemische geneeswijze ontwikkelde zich in deze periode. De werkzame stoffen in de kruiden werden chemisch nagebootst. Deze tak van geneeskunst werd de basis van allopathie.</p>

              <p>Het lichaam is zo gemaakt dat het een zelfhelend vermogen heeft gekregen van de Schepper. Als er ziekte is, komt dat doordat de wetten van de natuur niet worden nageleefd. Dit uit zich in het lichaam door tekorten of doordat er een accumulatie is van afvalstoffen en irriterende stoffen. Kruiden werken met het  lichaam mee om:</p>

              <ul>
                <li>Verontreinigingen uit te scheiden: Overtollige gifstoffen, afvalstoffen.</li>
                <li>Juiste toestand herstellen: door tekorten aan te vullen (mineralen, vitaminen, hormonen) en balans terugbrengen.</li>
                <li>Symptoom regulatie; crisis management, pijnbestrijding</li>
              </ul>

              <p>Afhankelijk van jouw persoonlijke situatie wordt gekeken naar welke kruiden kunnen worden toegepast.</p>
            </Container>

          </div>
          <img src={herbs} style={{ marginBottom: "-5px" }} />
        </>}

      {active === 3 &&
        <>
          <div className={cx("mainContent")}>
            <Container style={{ maxWidth: "700px" }}>
              <h1>Lifestyle counseling</h1>

              <p>Zoals je al hebt kunnen lezen is Yirpa’s benadering een holistische benadering. Daarom wordt er ook gekeken naar de emotionele en geestelijke gezondheid. <br />
              Het belangrijkste is ten eerste om deze te achterhalen en daarna om een manier te vinden om hier op een goede manier mee om te gaan.</p>
              <blockquote>
                <p>De relatie die tussen geest en lichaam bestaat, is heel innig. Wanneer de ene is aangedaan, voelt de ander mee. De geestestoestand beïnvloed de gezondheid veel meer dan menigeen beseft. Vele van de ziekten waaraan mensen lijden, zijn het resultaat van geestelijke depressie. Verdriet, angst, ontevredenheid, zelfverwijt, schuld, wantrouwen, hebben alle de neiging om afbreuk te doen aan de levenskrachten en leiden tot verval en dood.</p>

                <p>Moed, hoop, geloof, sympathie en liefde bevorderen gezondheid en verlengen het leven. Een tevreden gemoed en een opgewekte geest betekenen gezondheid voor het lichaam en kracht voor de ziel. "Een vrolijk hart bevordert de genezing."</p>

                <p>In de behandeling van zieken moet het effect van geestelijke beïnvloeding niet over het hoofd worden gezien. Juist toegepast, is deze invloed een van de meest effectieve middelen om ziekte te bestrijden. <cite>– De weg tot gezondheid, E.G. White pg 97</cite></p>

                <p>Aan de hand van Bijbelse principes zal ik hierin counseling geven. </p>

              </blockquote>


            </Container>



          </div>
          <img src={counselling} style={{ marginBottom: "-5px" }} />
        </>}

      {active === 4 &&
        <>
          <div className={cx("mainContent")}>
            <Container style={{ maxWidth: "700px" }}>
              <h1>Detox programma</h1>

              <h3>Wat houdt het in?</h3>
              <p>Ontgifting, meer algemeen "detox" genoemd, is een natuurlijk proces dat de gifstoffen die het lichaam binnendringen neutraliseert en elimineert, om zo normale lichamelijke functies terug te vinden (versterkte immuniteit, betere slaapkwaliteit, vitaliteit, hormonaal evenwicht...).</p>

              <p>De normale ontgifting verloopt via 5 organen: de lever, de nieren, de darmen, de longen en de huid.  Deze organen zullen het binnendringen van toxische en kankerverwekkende stoffen in het lichaam beperken, ze neutraliseren en ze elimineren. Bij een detox worden deze specifieke organen geholpen om hun functie beter uit te voeren.</p>

              <h3>Hoe wordt dit gedaan?</h3>
              <p>
                Vasten: met basische voeding, sappen en theeën of alleen water.
                Waterbehandelingen: waardoor meer eliminatie plaatsvindt van afvalstoffen via huid, darmen en longen.
                Kruiden en pakkingen: om de lever en nieren aan te zetten tot activiteit.
                Darmspoelingen: om de eliminatie te bevorderen.
              </p>

              <p>Dit alles wordt samen met de NEWSTART® principes in een 5, 10, of 15 daags programma aangeboden.</p>

            </Container>

          </div>
          <img src={detox} style={{ marginBottom: "-5px" }} />
        </>}

        <ContactForm />

      <Footer />
    </main>
  )
};

export default Therapies
