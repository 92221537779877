import * as React from 'react'
import classNames from 'classnames/bind'
import * as styles from './Newstart.module.css'
import thumbnail from './thumbnail.png'
import Container from '../Container/Container'

const cx = classNames.bind(styles)

const Newstart = props => {

    return (
        <div className={cx("base")}>
            <Container flex style={{maxWidth: "900px"}}>
                <div>
                    <h2>NEWSTART® principes</h2>
                    <p>NEWSTART® is een acroniem dat staat voor nutrition (voeding), exercise (beweging), water, sunlight (zonlicht), temperance (matigheid), air (frisse lucht), rest (rust)en trust in God (vertrouwen in God). Het zijn principes die voortvloeien uit de wetten van de gezondheid. Als het lichaam in balans is functioneert deze het beste. Als het lichaam uit balans is, doordat de gezondheidswetten niet in acht zijn genomen, ontstaat er ziekte.</p>
                </div>
                <img src={thumbnail} />
            </Container>

        </div>

    )
}

export default Newstart;